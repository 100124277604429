<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
    <path fill="#333" d="M10.333 6.624L10.333 25.171 24.542 15.897z" transform="translate(-35 -337) translate(20.5 327) translate(4.5 4.103)"/>
  </svg>
</template>

<script>
export default {
name: "play"
}
</script>