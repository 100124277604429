<template>
  <div class="equipment-list-item d-flex justify-content-start align-items-center" v-if="!!equipmentItem.media">
    <div class="equipment-list-item-image">
      <img :src="equipmentItem.media.general[0].icon" :alt="equipmentItem.media.general[0].title" v-if="equipmentItem.media.general">
    </div>
    <div class="equipment-list-item-content">
      <p class="equipment-list-item-title">{{ equipmentItem.title }}</p>

      <a :href="equipmentItem.media.general[0].link+'?utm_source=BOXPT&utm_media='+slugify(parentItem.createdBy)+'&utm_content='+slugify(parentItem.title)"  v-if="equipmentItem.media.general" target="_blank" class="equipment-list-item-link d-inline-flex align-items-center justify-content-center">
        <p>Comprar</p>
        <div class="equipment-list-item-link-icon">
          <linkIcon/>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import linkIcon from "@/components/svg/link-icon.vue";

export default {
  name: "equipment-item",
  components : {
    linkIcon
  },
  props: {
    equipmentItem: {
      required: true,
    },
    parentItem: {
      required: true,
    },
  },
  methods: {
    slugify(str) {
     return str.replace(/\s+/g, '-');
    }
  }
}
</script>