<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
    <path fill="#777" d="M3.25 7.123c-.096 0-.192-.037-.265-.11-.147-.146-.147-.383 0-.53L7.468 2H4.976c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h3.399c.207 0 .375.168.375.375v3.403c0 .207-.168.375-.375.375S8 5.235 8 5.028v-2.5L3.515 7.013c-.073.073-.17.11-.265.11m5.123 1.625H1.625c-.207 0-.375-.168-.375-.375V1.625c0-.207.168-.375.375-.375h2.114c.207 0 .375.168.375.375S3.946 2 3.739 2H2v5.998h5.998V6.266c0-.207.168-.375.375-.375s.375.168.375.375v2.107c0 .207-.168.375-.375.375" transform="translate(-135 -1096) translate(20 1038) translate(0 30) translate(114 27)"/>
  </svg>
</template>

<script>
export default {
name: "link-icon"
}
</script>