<template>
  <div class="box-modal-wrapper box-video-modal">
    <div class="container">
      <div class="box-modal-header d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="toggleVideoModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-logo">
            <headerLogo/>
          </div>
      </div>
      <div class="box-modal-body d-flex justify-content-start align-items-center flex-column">
        <p class="box-modal-title">{{ video.title }}</p>
        <div class="box-modal-video-wrapper">
          <vueVimeoPlayer ref="modalVideoPlayer" :video-id="'https://player.vimeo.com/video/' + video.videoId" :key="video.videoId" @ended="ended"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import headerLogo from "@/components/svg/logo.vue";
import closeIcon from "@/components/svg/close.vue";
import { vueVimeoPlayer } from 'vue-vimeo-player';

import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
  name: "video-modal",
  components: {
    headerLogo,
    closeIcon,
    vueVimeoPlayer
  },
  props: {
    video: {
      required: true,
    },
    productID: {
      required: true,
    },
  },
  methods: {
    toggleVideoModal: function() {
      EventBus.$emit("bus-toggleVideoModal");

      if ( this.$refs.modalVideoPlayer != undefined ) {
        this.$refs.modalVideoPlayer.pause();
      }
    },
    ended: function () {
      EventBus.$emit("bus-toggleVideoModal");

      let authKey = window.$cookies.get("authKey");
      const historyRequestBody = {
        product: this.$props.productID,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'history', qs.stringify(historyRequestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {

            }
          })
          .catch((e) => {
            if ( e.response.status > 500 ) {
              this.errors.push(e);
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            } else {
              this.errors.push(e.response.data.errors);
              this.showMessage = true;
              this.formResponseMessage = e.response.data.errors;
            }
          });
    },
  },
  mounted() {
    EventBus.$on('bus-playVideo', () => {
      if ( this.$refs.modalVideoPlayer != undefined ) {
        this.$refs.modalVideoPlayer.play();
      }
    });
  },
}


</script>