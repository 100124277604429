<template>
  <div class="equipment-wrapper section-padding ">
    <div class="container">
      <div class="equipment-heading">
        <p class="section-title">Equipamento</p>
      </div>
      <div class="equipment-list">
        <div class="row equipment-list-row">
          <div class="col-12 col-lg-4 equipment-list-column" v-for="(item,index) in equipment" :key="index">
            <equipmentItem :parentItem="parentItem" :equipmentItem="item"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import equipmentItem from "@/components/equipment/equipment-item.vue";

export default {
  name: "equipment-block",
  components: {
    equipmentItem,
  },
  props: {
    equipment: {
      required: true,
    },
    parentItem: {
      required: true,
    },
  },
}
</script>