<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#333" d="M19.957 21.141c-.511.251-1.094.281-1.643.084-.559-.2-1.015-.611-1.282-1.156-.546-1.114-.124-2.447.944-2.97.288-.142.598-.212.913-.212.244 0 .49.042.73.129.56.2 1.014.61 1.281 1.156.546 1.114.124 2.447-.943 2.969m-10.41-7.08c-.18.553-.56.998-1.072 1.248-.511.251-1.095.28-1.643.084-.559-.2-1.014-.611-1.282-1.157-.546-1.113-.124-2.446.944-2.968.288-.143.598-.213.913-.213.244 0 .49.042.73.128.559.201 1.014.612 1.281 1.157.267.545.314 1.155.13 1.72m7.354-7.285c.18-.555.561-.998 1.074-1.249.288-.141.598-.212.913-.212.244 0 .49.042.73.128.56.201 1.014.612 1.281 1.157.546 1.114.124 2.447-.943 2.969-1.066.523-2.378.041-2.925-1.073-.267-.545-.313-1.156-.13-1.72m3.385 8.379c-1.057-.38-2.186-.32-3.183.169-.513.252-.95.6-1.3 1.012l-4.273-2.123c.128-.618.125-1.249-.024-1.86l4.29-2.16c.814.995 2.016 1.566 3.252 1.566.6 0 1.208-.133 1.78-.413 2.045-1.003 2.874-3.523 1.848-5.618-.496-1.009-1.343-1.772-2.39-2.147-1.057-.38-2.187-.32-3.183.17-.996.488-1.736 1.345-2.083 2.413-.239.735-.257 1.506-.09 2.25l-4.327 2.179c-.478-.57-1.088-1.015-1.798-1.27-1.058-.38-2.188-.321-3.184.169-2.046 1.002-2.875 3.523-1.848 5.617.495 1.01 1.344 1.772 2.39 2.147.458.165.93.246 1.4.246.612 0 1.219-.14 1.783-.415.531-.261.983-.633 1.348-1.08l4.244 2.109c-.208.917-.132 1.909.317 2.825.495 1.01 1.344 1.772 2.39 2.147.457.165.93.246 1.4.246.612 0 1.22-.14 1.783-.415 2.045-1.003 2.874-3.523 1.848-5.617-.496-1.01-1.343-1.772-2.39-2.147" transform="translate(-1723 -151) translate(1713 141) translate(6.667 6.667)"/>
  </svg>
</template>

<script>
export default {
name: "share-icon"
}
</script>